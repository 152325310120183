
import { useEffect, useState } from "react";
import { getCompanyPreferences, editCompanyPreference, addCompanyPreference } from "../../services/companiesService";

const useCompanyPreference = (props) => {

    const prefInitObject = () => {
        return {
            id: null,
            company: null,
            value: null,
            preference: null,
        }
    };

    const [prefsState, setPrefsState] = useState({
        isLoading: false,
        items: [],
        prefEditObject: prefInitObject(),
        prefCreateObject: null,
        //todo make it global with custom events
        confirmDialog: {
            message: '',
            title: '',
            onCancel: () => { },
            onConfirm: () => { },
            isLoading: false,
            isOpen: false
        },
        error: {},
        errorMessage: {},
        isPreferenceSaved: {}
    });


    const pathName = window.location.pathname;
    const regex = /(\d+)/gm;
    let m;

    m = regex.exec(pathName);


    const companyId = m[0];

    useEffect(() => {

        getCompanyPreferences({ id: companyId }).then(res => {
            const results = res.data;
            //initialize error items
            let errorArray = [];
            for (let i = 0; i < results.length; i++) {
                errorArray[i] = false;
            }
            //initialize saved items
            let savedArray = [];
            for (let i = 0; i < results.length; i++) {
                savedArray[i] = true;
            }
            setPrefsState({
                ...prefsState,
                isLoading: false,
                error: errorArray,
                isPreferenceSaved: savedArray,
                items: results
            });
        }).catch((error) => {
            setPrefsState({
                ...prefsState,
                isLoading: false,
            })

        });
        // eslint-disable-next-line  
    }, []);

    const getPrefVal = (data, pos) => {
     

        if (prefsState.items[pos].id) {
            const prefEditObject = Object.assign({}, prefsState.items[pos]);

            let error = false;
            if (data !== undefined
                && data !== null
               // && data.notes !== ''
                && prefsState.items[pos].preference.description === 'Minimum unit cost for RFID tagging'
                && (data.notes >= 80 || data.notes==0 )
            ) {
                prefEditObject.value = data.notes;
            }
            else if (
                prefsState.items[pos].preference.description !== 'Minimum unit cost for RFID tagging'
                && data !== undefined && data !== null
                && data.notes !== '') {

                prefEditObject.value = data.notes;
            } else if (prefsState.items[pos].preference.description === 'Minimum unit cost for RFID tagging') {
                error = true;
                setPrefsState({
                    ...prefsState,
                    isLoading: false,
                    error: {
                        ...prefsState.error,
                        [pos]: true
                    },
                    errorMessage: {
                        ...prefsState.errorMessage,
                        [pos]: 'Minimum unit cost is 80',
                    },
                    isPreferenceSaved: {
                        ...prefsState.isPreferenceSaved,
                        [pos]: false
                    },
                });
            } else {

                prefEditObject.value = null;
            }
            

            !error && editCompanyPreference({ id: companyId }, prefEditObject)
                .then(res => {
                    const results = res.data;
                    setPrefsState(
                        oldPrefsState => {
                            return {
                                ...oldPrefsState,
                                isLoading: false,
                                items: oldPrefsState.items.map((item, index) => {
                                    return index === pos
                                        ? results : item
                                }),
                                isPreferenceSaved: {
                                    ...oldPrefsState.isPreferenceSaved,
                                    [pos]: true
                                },
                                error: {
                                    ...oldPrefsState.error,
                                    [pos]: false
                                },
                            }
                        }
                    );

                }).catch((error) => {
                    // dispatch(messagesActions.openSnackbar({
                    //   variant: 'error ',
                    //   message: ' '  error,
                    // }));
                    setPrefsState({
                        ...prefsState,
                        isLoading: false,
                        error: {
                            ...prefsState.error,
                            [pos]: true
                        },
                        isPreferenceSaved: {
                            ...prefsState.isPreferenceSaved,
                            [pos]: false
                        },
                    });
                });
        } else {
            const prefCreateObject = Object.assign({}, prefsState.items[pos]);

            let error = false;
            if (data !== undefined
                && data !== null
                && data.notes !== ''
                && prefsState.items[pos].preference.description === 'Minimum unit cost for RFID tagging'
                && (data.notes >= 80 )
            ) {
                prefCreateObject.value = data.notes;
            }
            else if (
                prefsState.items[pos].preference.description !== 'Minimum unit cost for RFID tagging'
                && data !== undefined && data !== null
                && data.notes !== '') {

                prefCreateObject.value = data.notes;
            } else if (prefsState.items[pos].preference.description === 'Minimum unit cost for RFID tagging') {
                error = true;
                setPrefsState({
                    ...prefsState,
                    isLoading: false,
                    error: {
                        ...prefsState.error,
                        [pos]: true
                    },
                    isPreferenceSaved: {
                        ...prefsState.isPreferenceSaved,
                        [pos]: false
                    },
                });
            } else {

                prefCreateObject.value = null;
            }

            // if (data !== undefined && data !== null && data.notes !== '') {
            //     prefCreateObject.value = data.notes;
            // } else {
            //     prefCreateObject.value = null;
            // }

            const newPref = {};
            newPref.id = companyId;
            newPref.preference = prefCreateObject;

            !error&&addCompanyPreference({ id: companyId }, prefCreateObject)
                .then(res => {
                    const results = res.data;

                    setPrefsState(
                        oldPrefsState => {
                            return {
                                ...oldPrefsState,
                                isLoading: false,
                                items: oldPrefsState.items.map((item, index) => {
                                    return index === pos
                                        ? results : item
                                }),
                                isPreferenceSaved: {
                                    ...oldPrefsState.isPreferenceSaved,
                                    [pos]: true
                                },
                                error: {
                                    ...oldPrefsState.error,
                                    [pos]: false
                                },
                            }
                        }
                    );

                }).catch((error) => {
                    // dispatch(messagesActions.openSnackbar({
                    //   variant: 'error ',
                    //   message: ' '  error,
                    // }));
                    setPrefsState({
                        ...prefsState,
                        isLoading: false,
                        error: {
                            ...prefsState.error,
                            [pos]: true
                        },
                        isPreferenceSaved: {
                            ...prefsState.isPreferenceSaved,
                            [pos]: false
                        },
                    });
                });
        }
    };


    return {
        prefsState,
        getPrefVal
    }

}
export default useCompanyPreference;