import httpService from './httpService';

const getTransactionById = (criteria = {}) => {

  return httpService.FetchTransaction(criteria);
};

const getTransactions = (criteria = {}) => {

  return httpService.FetchTransactionList(criteria);
};

const deleteTransaction = (id) => {
  return httpService.DeleteTransaction(id);
};

const getAllTransactionsInfo = (criteria = {}) => {

  return httpService.FetchTransactionInfoList(criteria);
};

const addTransactionSupplier = (data) => {
  return httpService.AddTransactionSupplier(data);
};

const editTransactionAgent = (id,data) => {
  return httpService.UpdateTransactionAgent(id.id,data);
};

const compareTransaction = (id,data) => {
  return httpService.CompareTransaction(id,data);
};

export { getTransactionById, getTransactions, getAllTransactionsInfo, addTransactionSupplier, editTransactionAgent, compareTransaction, deleteTransaction };