import React, {useState, useEffect, useRef} from 'react';
import {
    IconButton, Grid
} from "@mui/material";

import Drawer from '@mui/material/Drawer';
import ModeCommentIconWithNumber from "../modeCommentIconWithNumber";
import ChatBox from '../chatBox';
import {useChatService} from "../../hooks/useChatService";

export default function MembersChat({ relatedId, entityId, entityType, ownerId, recipient }) {
  
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const {
        messages,
        setMessages,
        createMessage,
        loadMessages,
        loadUnreadMessages,
        unreadMessagesCount,
        setUnreadMessagesCount,
        makeMessagesRead,
        getOpen
    } = useChatService(entityId, entityType );

    const onToggleDrawer = (anchor, open) => async (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(open) {
            if(messages.length>= 0) {
                await loadMessages();
            }
            if(unreadMessagesCount > 0) {
                await makeMessagesRead();
            }
        }
        setState({ ...state, [anchor]: open });
    };

    const onMessageSend = (messageText) => createMessage(entityId, entityType,  messageText);

    useEffect(() => {
        const chatBoxEl = document.querySelector("#scroll-style");
        state.right&&messages.length> 0&&makeMessagesRead(); 
       
        !state.right&&loadUnreadMessages(); 
        if(!!messages && messages.length && chatBoxEl) {
            chatBoxEl.scrollTop = chatBoxEl.scrollHeight;
        }
    }, [messages])

    return (
        <div>
            <React.Fragment key={'right'}>
                <Grid
                    container
                    justifyContent={"flex-end"}
                ><Grid container item>
                        <Drawer
                            anchor={'right'}
                            open={state['right']}
                            onClose={onToggleDrawer('right', false)}
                        >
                            <ChatBox
                                messages={messages}
                                relatedId={relatedId}
                                ownerId={ownerId}
                                recipient={recipient}
                                onMessageSend={onMessageSend}
                            />
                        </Drawer>
                    </Grid>
                    <Grid container item justifyContent={"flex-end"}>
                        <IconButton
                            variant="contained"
                            color="primary"
                            sx={{
                                'position': 'fixed',
                                'bottom': 0,

                            }}
                            onClick={onToggleDrawer('right', true)}
                        >
                            <ModeCommentIconWithNumber size={16} count={unreadMessagesCount} />
                        </IconButton>

                    </Grid>
                </Grid>
            </React.Fragment>
        </div>
    );
}