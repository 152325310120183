import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import React, { useEffect, useState, useRef,Fragment, } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeaders, newContract } from '../../helpers/contractsRegionConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllRegionContractRegions, addOriginalRegionCRegion, getAllContractRegions, deleteOriginalRegionData } from '../../services/originalRegionService';
import { FilterByText } from '../../components/filterByText';
import FilterContractRegions from '../../components/filterContractRegions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dateToString from '../../helpers/dateToString';
import FilterContracts from '../../components/filterContracts';
import moment from 'moment';
import './_contractsRegion.scss';
import t from '../../helpers/languages';

const ContractsRegion = (props) => {

  const contractInitObject = () => {
    return {
      id: '?',
      name: '',
      contract: { name: '', id: '',dateFrom:'',dateTo:'' },
    };
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });


  const filters = localStoreGetObj('contractsRegionFilter');

  const [contractsState, setContractsState] = useState({
    contracts: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    contractEditIndex: -1,
    contractEditObject: contractInitObject(),
    contractCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });
  
  const [searchDate, setSearchDate] = useState(new Date());

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let regionId = null;

  if (!props.autonomousPage) {
    const pathName = window.location.pathname;
    const regex = /(\d+)/gm;
    let m;
    m = regex.exec(pathName);
    regionId = m[0];
  }


  const filtersData = {
    ...contractsState.filters,
    search: filters && filters.searchText,
    per_page: criteria.per_page,
    page: criteria.page,
    order_type: contractsState.page.order_type,
    order_by: contractsState.page.order_by,
  };


  useEffect(function () {
    getAllRegionContractRegions(regionId, filtersData).then(res => {
      const { results } = res.data;
      setContractsState({
        ...contractsState,
        contracts: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });

      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setContractsState({
        ...contractsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === contractsState.page.order_by && contractsState.page.order_type === 'desc' ? 'asc' : 'desc';

    setContractsState({
      ...contractsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllRegionContractRegions(regionId, filtersData).then(res => {
      const { results } = res.data;
      setContractsState({
        ...contractsState,
        contracts: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setContractsState({
        ...contractsState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setContractsState({
      ...contractsState,
      isLoading: true
    });
    const newCriteria = {
      ...contractsState.filters,
      per_page: contractsState.page.per_page,
      page: newPage + 1
    };
    getAllRegionContractRegions(regionId, newCriteria).then(res => {
      setContractsState({
        ...contractsState,
        isLoading: false,
        contracts: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setContractsState({
      ...contractsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...contractsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllRegionContractRegions(regionId, newCriteria).then(res => {
      setContractsState({
        ...contractsState,
        isLoading: false,
        contracts: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setContractsState({
        ...contractsState,
        isLoading: false,
      });
    });
  };

  const handleEditPricelist = (event, contractIndex) => {

    event.stopPropagation();

    const contractEditObject = Object.assign({}, contractsState.contracts[contractIndex]);


    setContractsState({
      ...contractsState,
      contractEditIndex: contractIndex,
      contractEditObject: contractEditObject
    });


  };

  const handleCopyPricelist = (e, contractIndex) => {

    e.preventDefault();

    const contractCopyObject = Object.assign({}, contractsState.contracts[contractIndex]);

    setContractsState({
      ...contractsState,
      contractCreateObject: { contractRegion: contractCopyObject },
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);

  };

  const onEditablePricelistChange = (event, property) => {

    const newValue = event.target.value;

    const newEditPricelistItem = Object.assign({}, contractsState.contractEditObject);
    newEditPricelistItem[property] = newValue;

    setContractsState({
      ...contractsState,
      contractEditObject: newEditPricelistItem
    });
  };


  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSavePricelist = (event, props) => {
    event.preventDefault();
    setContractsState({
      ...contractsState,
      isLoading: true
    });
    const newEditPricelistItem = Object.assign({}, contractsState.contractEditObject);

    if (validator.current.allValid()) {
      editVessel(newEditPricelistItem.id, newEditPricelistItem)
        .then(re => {
          const editedPricelist = re.data;
          setContractsState(oldContracts => {
            return {
              ...oldContracts,
              contracts: oldContracts.contracts.map(contract => {
                return contract.id === editedPricelist.id
                  ? re.data : contract
              }),
              contractEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setContractsState({
            ...contractsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setContractsState({
        ...contractsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditPricelist = (e, index) => {
    setContractsState({
      ...contractsState,
      contractEditIndex: -1
    })
  };

  const handleDeletePricelist = (event, index) => {

    const contractForDeletion = {};

    contractForDeletion.contractRegions = [{
      id: contractsState.contracts[index].id,
      name: contractsState.contracts[index].name,
    }];

    setContractsState({
      ...contractsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete contract region',
        onCancel: () => {
          setContractsState({
            ...contractsState,
            confirmDialog: {
              ...contractsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteOriginalRegionData(regionId,contractForDeletion).then(() => {
            const newContracts = [...contractsState.contracts];
            newContracts.splice(index, 1);
            setContractsState({
              ...contractsState,
              contracts: newContracts,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewPricelist = (e) => {
    e.preventDefault();

    setContractsState({
      ...contractsState,
      contractCreateObject: newContract()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewPricelist = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newContract = {};

    newContract.contractRegions = [{
      id: contractsState.contractCreateObject.contractRegion.id,
      name: contractsState.contractCreateObject.contractRegion.name,
    }];


    if (validator.current.allValid()) {
      addOriginalRegionCRegion(regionId, newContract)
        .then(res => {
          setContractsState({
            ...contractsState,
            contracts: [...contractsState.contracts, res.data],
            contractCreateObject: null,
            contractEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setContractsState({
            ...contractsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewPricelistObject = (e, property) => {
    const newValue = e.target.value;

    const newEditPricelistItem = Object.assign({}, contractsState.contractCreateObject);
    newEditPricelistItem[property] = newValue;

    setContractsState({
      ...contractsState,
      contractCreateObject: newEditPricelistItem
    });
  };


  const selectedContractRegion = (data, action) => {

    const newEditContractRegion = Object.assign({},
      action === 'edit'
        ? contractsState.contractEditObject
        : contractsState.contractCreateObject
    );
   
    newEditContractRegion.contractRegion = data.contractRegion;

    setContractsState({
      ...contractsState,
      [action === "edit" ? "contractEditObject" : "contractCreateObject"]:
        newEditContractRegion,
    });
  };

  const filterData = (data) => {
    setContractsState({
      ...contractsState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...contractsState.filters,
      search: data && data.searchText,
    };

    getAllRegionContractRegions(regionId, filtersData).then(res => {
      const { results } = res.data;

      setContractsState({
        ...contractsState,
        contracts: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('contractsRegionFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setContractsState({
        ...contractsState,
        isLoading: false,
      });
    });

  };

  const onDateFromChange = (date) => {
    setSearchDate(date);
    setContract([]);

  };



  const selectedContract = (data, action) => {

    const newEditContractItem  = Object.assign(
      {},
      action === "edit"
        ? contractsState.contractEditObject
        : contractsState.contractCreateObject
    );

    newEditContractItem.contract = data.contract;

    setContractsState({
      ...contractsState,
      [action === "edit" ? "contractEditObject" : "contractCreateObject"]:
      newEditContractItem ,
    });
  }

   


  const tableFilterComponent = () => {
    return <FilterByText
      searchText={(filters && filters.searchText) ? filters.searchText : contractsState.filters.search}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(contractsState.contracts) && contractsState.contracts.map((contract, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (contractsState.contractEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{contract.name}</TableCell>
                    <TableCell>{contract.contract?.name}</TableCell>
                    <TableCell>{contract.contract?.dateFrom}</TableCell>
                    <TableCell>{contract.contract?.dateTo}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterContractRegions
                          contractRegion={contractsState.contractEditObject || null}
                          getSelectedContractRegion={(data) => selectedContractRegion(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>


                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: false,
                    callback: () => null
                  },
                  // copy: {
                  //   enabled: contractsState.contractEditIndex !== index,
                  //   callback: (e) => handleCopyPricelist(e, index)
                  // },
                  delete: {
                    enabled: contractsState.contractEditIndex !== index,
                    callback: (e) => handleDeletePricelist(e, index)
                  },
                  close: {
                    enabled: contractsState.contractEditIndex === index,
                    callback: (e) => handleCloseEditPricelist(e, index)
                  },
                  save: {
                    enabled: false,
                    callback: (e) => handleSavePricelist(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}
        {
          contractsState.contractCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container spacing={1}>
                    <Fragment>
                      <Grid item xs={6}>
                        <DatePicker
                          id="date-picker-from"
                          minDate={new Date('2022-01-01')}
                          maxDate={new Date(2030, 1, 1)}
                          label={t('DATE')}
                          value={searchDate}
                          inputFormat="yyyy-MM-DD"
                          inputVariant="outlined"
                          onChange={onDateFromChange}
                          initialFocusedDate={searchDate}
                          clearable
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                    </Fragment>
                  </Grid>
                </LocalizationProvider>
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl fullWidth variant="outlined">
                <FilterContracts
                  contract={contractsState.contractCreateObject.contract||null}
                  searchDate={dateToString(moment.utc(searchDate)) + 'T00:00:00Z'}
                  getSelectedContract={selectedContract} />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterContractRegions
                  contract={contractsState.contractCreateObject.contract}
                  contractRegion={contractsState.contractCreateObject.contractRegion || null}
                  getSelectedContractRegion={(data) => selectedContractRegion(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setContractsState({ ...contractsState, contractCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewPricelist()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('CONTRACT_REGIONS')}
        pagination={{
          total: contractsState.page.total || 0,
          page: contractsState.page.page - 1 || 0,
          perPage: contractsState.page.per_page || 10,
          count: contractsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewPricelist}
        newRowBtnEnabled={!contractsState.contractCreateObject}
        confirmDialog={contractsState.confirmDialog.message.length > 0 ? contractsState.confirmDialog : contractsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={contractsState.isLoading}
      />
    </>
  );
};


export default ContractsRegion;
