import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { debounce, Grid, } from "@mui/material";
import FilterVesselStoreStatuses from '../filterVesselStoreStatuses';
import FilterVesselStoreTypes from '../filterVesselStoreTypes';
import './_productsInventoryTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const ProductsInventoryTableFilter = ({
  vesseStoreType: initialVesseStoreType,
  vesselStoreStatus: initialVesselStoreStatus,
  getFilterData
}) => {

  const classes = useStyles();

  const [vesseStoreType, setVesseStoreType] = useState(initialVesseStoreType || null);
  const [vesselStoreStatus, setVesselStoreStatus] = useState(initialVesselStoreStatus || null);

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const selectedVesseStoreType = (data) => {
    setVesseStoreType(data.vesseStoreType);
    const filterData = {
      vesseStoreType: data.vesseStoreType,
      vesselStoreStatus: vesselStoreStatus,
    };

    getFilterData(filterData);
  }

  const selectedVesselStoreStatus = (data) => {
    setVesselStoreStatus(data.vesselStoreStatus);
    const filterData = {
      vesseStoreType: vesseStoreType,
      vesselStoreStatus: data.vesselStoreStatus,
    };

    getFilterData(filterData);
  }

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterVesselStoreStatuses
              vesselStoreStatus={vesselStoreStatus}
              getSelectedVesselStoreStatus={selectedVesselStoreStatus}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterVesselStoreTypes
              vesseStoreType={vesseStoreType}
              getSelectedVesseStoreType={selectedVesseStoreType}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}