// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import AvTimerIcon from '@mui/icons-material/AvTimer';
// import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import ReportProblemIcon from '@mui/icons-material/ReportProblem';
// import ShopIcon from '@mui/icons-material/Shop';
// import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; 
// import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import GroupIcon from '@mui/icons-material/Group';
// import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import BallotIcon from '@mui/icons-material/Ballot';
import ListIcon from '@mui/icons-material/List';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ShopIcon from '@mui/icons-material/Shop';
import OpacityIcon from '@mui/icons-material/Opacity';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BookIcon from '@mui/icons-material/Book';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ClearIcon from '@mui/icons-material/Clear';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TableViewIcon from '@mui/icons-material/TableView';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import t from './languages';

const suppliersSidenav = {
  mainSection: [
    // {
    //   id: 'suppliers-home',
    //   name: t('TRANSACTIONS'),
    //   routeLink: '/',
    //   icon: HomeIcon,
    //   authorizationFlag: null
    // },
    {
      id: 'suppliers-dashboard',
      name: t('DASHBOARDS'),
      routeLink: '/dashboards',
      icon: DashboardIcon,
      authorizationFlag: 'transaction'
    },
    {
      id: 'suppliers-transactions',
      name: t('TRANSACTIONS'),
      routeLink: '/',
      icon: BallotIcon,
      authorizationFlag: 'transaction'
    },
    {
      id: 'suppliers-RFQS',
      name: t('RFQS'),
      routeLink: '/rfqs',
      icon: ListAltIcon,
      authorizationFlag: 'rfq'
    },
    {
      id: 'suppliers-Quotes',
      name: t('QUOTES'),
      routeLink: '/quotes',
      icon: LocalOfferIcon,
      authorizationFlag: 'quote'
    },
    {
      id: 'suppliers-Orders',
      name: t('ORDERS'),
      routeLink: '/orders',
      icon: ShopIcon,
      authorizationFlag: 'order'
    },
    {
      id: 'suppliers-Deliveries',
      name: t('DELIVERIES'),
      routeLink: '/deliveries',
      icon: LocalShippingIcon,
      authorizationFlag: null
    },
  ],
  secondarySection: [{
    id: 'suppliers-Reports',
    name: t('REPORTS'),
    icon: AssessmentIcon,
    items: [
      {
        id: 'suppliers-region-purchases',
        name: t('REGION_PURCHASES'),
        routeLink: '/region-purchases',
        icon: ListIcon,
        authorizationFlag: 'region_purchase'
      },
      {
        id: 'suppliers-vessel-purchases',
        name: t('VESSEL_PURCHASES'),
        routeLink: '/vessel-purchases',
        icon: ListIcon,
        authorizationFlag: 'vessel_purchase'
      },
    ]
  }],
  thirdSection: [],
  // secondarySection: [
  //   {
  //     id: 'suppliers-deliveries',
  //     name: t('DELIVERIES'),
  //     routeLink: '/deliveries',
  //     icon: LocalShippingIcon,
  //     authorizationFlag: 'delivery'
  //   },
  //   {
  //     id: 'suppliers-Requests',
  //     name: t('REQUESTS'),
  //     routeLink: '/requests',
  //     icon: AvTimerIcon,
  //     authorizationFlag: null
  //   },
  //   {
  //     id: 'suppliers-Sales',
  //     name: t('SALES'),
  //     routeLink: '/sales',
  //     icon: AttachMoneyIcon,
  //     authorizationFlag: null
  //   },
  //   {
  //     id: 'suppliers-Effectiveness',
  //     name: t('EFFECTIVENESS'),
  //     routeLink: '/effectiveness',
  //     icon: EmojiObjectsIcon,
  //     authorizationFlag: null
  //   },
  //   {
  //     id: 'suppliers-Performance',
  //     name: t('PERFORMANCE'),
  //     routeLink: '/performance',
  //     icon: BatteryCharging90Icon,
  //     authorizationFlag: null
  //   },
  // ],
  accountSettings: [
    {
      id: 'suppliers-account-settings',
      name: t('ACCOUNT_SETTINGS'),
      routeLink: '/account-settings',
      icon: SettingsIcon,
      authorizationFlag: null
    },
  ],
};

const buyersSidenav = {
  mainSection: [
    // {
    //   id: 'buyers-home',
    //   name: t('TRANSACTIONS'),
    //   routeLink: '/',
    //   icon: HomeIcon,
    //   authorizationFlag: null
    // },
    {
      id: 'buyers-dashboard',
      name: t('DASHBOARDS'),
      routeLink: '/dashboards',
      icon: DashboardIcon,
      authorizationFlag: 'transaction'
    },
    {
      id: 'buyers-transactions',
      name: t('TRANSACTIONS'),
      routeLink: '/',
      icon: BallotIcon,
      authorizationFlag: 'transaction'
    },
    {
      id: 'buyers-RFQS',
      name: t('RFQS'),
      routeLink: '/Rfqs',
      icon: ListAltIcon,
      authorizationFlag: 'rfq'
    },
    {
      id: 'buyers-Quotes',
      name: t('QUOTES'),
      routeLink: '/quotes',
      icon: LocalOfferIcon,
      authorizationFlag: 'quote'
    },
    {
      id: 'buyers-Orders',
      name: t('ORDERS'),
      routeLink: '/orders',
      icon: ShopIcon,
      authorizationFlag: 'order'
    },
    {
      id: 'buyers-Deliveries',
      name: t('DELIVERIES'),
      routeLink: '/deliveries',
      icon: LocalShippingIcon,
      authorizationFlag: null
    },
    {
      id: 'buyers-vessels',
      name: t('VESSELS'),
      routeLink: '/vessels',
      icon: DirectionsBoatIcon,
      authorizationFlag: 'vessel'
    },
    {
      id: 'buyer-rated-products',
      name: t('RATED_PRODUCTS'),
      routeLink: '/rated-products',
      icon: ListIcon,
      authorizationFlag: 'product'
    },
    {
      id: 'buyer-pricelist-viewer',
      name: t('PRICE_LISTS'),
      routeLink: '/pricelists-comparison',
      icon: PriceCheckIcon,
      authorizationFlag: 'product'
    },
  ],
  secondarySection: [{
    id: 'buyers-Reports',
    name: t('REPORTS'),
    icon: AssessmentIcon,
    items: [
      {
        id: 'buyers-region-purchases',
        name: t('REGION_PURCHASES'),
        routeLink: '/region-purchases',
        icon: ListIcon,
        authorizationFlag: 'region_purchase'
      },
      {
        id: 'buyers-vessel-purchases',
        name: t('BUYER_PURCHASES'),
        routeLink: '/vessel-purchases',
        icon: ListIcon,
        authorizationFlag: 'vessel_purchase'
      },
    ]
  }],
  thirdSection: [],
  // secondarySection: [
  //   {
  //     id: 'buyers-deliveries',
  //     name: t('DELIVERIES'),
  //     routeLink: '/deliveries',
  //     icon: LocalShippingIcon,
  //     authorizationFlag: 'delivery'
  //   },
  //   {
  //     id: 'buyers-Requests',
  //     name: t('REQUESTS'),
  //     routeLink: '/requests',
  //     icon: AvTimerIcon,
  //     authorizationFlag: null
  //   },
  //   {
  //     id: 'buyers-Port-Allocation',
  //     name: t('PORT_ALLOCATION'),
  //     routeLink: '/Ports',
  //     icon: SwapHorizIcon,
  //     authorizationFlag: 'port'
  //   },
  //   {
  //     id: 'buyers-Vessel-Allocation',
  //     name: t('VESSEL_ALLOCATION'),
  //     routeLink: '/Vessels',
  //     icon: SwapHorizontalCircleIcon,
  //     authorizationFlag: 'vessel'
  //   },
  //   {
  //     id: 'buyers-OpEx',
  //     name: t('OPEX'),
  //     routeLink: '/Opex',
  //     icon: AssessmentIcon,
  //     authorizationFlag: null
  //   },
  // ],
  accountSettings: [
    {
      id: 'buyers-account-settings',
      name: t('ACCOUNT_SETTINGS'),
      routeLink: '/account-settings',
      icon: SettingsIcon,
      authorizationFlag: null
    },
  ],
};

const mbasSidenav = {
  mainSection: [
    {
      id: 'mbas-dashboard',
      name: t('DASHBOARDS'),
      routeLink: '/dashboards',
      icon: DashboardIcon,
      authorizationFlag: null,
    },
    {
      id: 'mbas-home',
      name: t('TRANSACTIONS'),
      routeLink: '/',
      icon: BallotIcon,
      authorizationFlag: null
    },   
    // {
    //   id: 'mbas-suppliers',
    //   name: t('SUPPLIERS'),
    //   routeLink: '/suppliers',
    //   icon: SupervisedUserCircleIcon,
    //   authorizationFlag: null
    // },
    {
      id: 'mbas-vessels',
      name: t('VESSELS'),
      routeLink: '/vessels',
      icon: DirectionsBoatIcon,
      authorizationFlag: 'vessel'
    },
    {
      id: 'mbas-companies',
      name: t('COMPANIES'),
      routeLink: '/companies',
      icon: BusinessIcon,
      authorizationFlag: 'company'
    },
    {
      id: 'mbas-pricelist-viewer',
      name: t('PRICE_LISTS'),
      routeLink: '/pricelists-comparison',
      icon: PriceCheckIcon,
      authorizationFlag: 'product'
    },
    {
      id: 'mbas-clear-cache-refresh-views',
      name: t('CLEAR_REFRESH'),
      routeLink: '/clear-cache-refresh-views',
      icon: ClearIcon,
      authorizationFlag: null
    }
  ],
  secondarySection: [
    {
      id: 'mba-match-list',
      name: t('MATCH_LIST'),
      icon: OpacityIcon,
      items: [
        {
          id: 'mbas-port-regions',
          name: t('REGIONS'),
          routeLink: '/regions',
          icon: SouthAmericaIcon,
          authorizationFlag: 'product'
        },
        {
          id: 'mbas-products',
          name: t('PRODUCT_LIST'),
          routeLink: '/products',
          icon: ListIcon,
          authorizationFlag: 'product'
        },
        {
          id: 'mbas-product-match',
          name: t('PRODUCT_MATCH'),
          routeLink: '/product/match',
          icon: CompareArrowsIcon,
          authorizationFlag: null
        },
        {
          id: 'product-dictionaries',
          name: t('PRODUCT_DICTIONARIES'),
          routeLink: '/product-dictionaries',
          icon: LibraryBooksIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-equivalents-product',
          name: t('PRODUCT_EQUIVALENTS'),
          routeLink: '/product-equivalents',
          icon: ListIcon,
          authorizationFlag: 'product'
        },
        {
          id: 'mbas-ports',
          name: t('PORT_LIST'),
          routeLink: '/ports',
          icon: ListIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-port-match',
          name: t('PORT_MATCH'),
          routeLink: '/port/match',
          icon: CompareArrowsIcon,
          authorizationFlag: null
        },
        {
          id: 'port-dictionaries',
          name: t('PORT_DICTIONARIES'),
          routeLink: '/port-dictionaries',
          icon: LibraryBooksIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-utils',
          name: t('UNIT_LIST'),
          routeLink: '/units',
          icon: ListIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-unit-match',
          name: t('UNIT_MATCH'),
          routeLink: '/unit/match',
          icon: CompareArrowsIcon,
          authorizationFlag: null
        },
        {
          id: 'unit-dictionaries',
          name: t('UNIT_DICTIONARIES'),
          routeLink: '/unit-dictionaries',
          icon: LibraryBooksIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-countries',
          name: t('COUNTRY_LIST'),
          routeLink: '/countries',
          icon: ListIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-countries-match',
          name: t('COUNTRY_MATCH'),
          routeLink: '/country/match',
          icon: CompareArrowsIcon,
          authorizationFlag: null
        },
        {
          id: 'country-dictionaries',
          name: t('COUNTRY_DICTIONARIES'),
          routeLink: '/country-dictionaries',
          icon: LibraryBooksIcon,
          authorizationFlag: null
        }
      ]
    }
  ],
  thirdSection: [
    {
      id: 'mbas-data-logs',
      name: t('LOGS'),
      icon: FeaturedPlayListIcon,
      items: [
        {
          id: 'mbas-com-audit',
          name: t('COM_AUDIT'),
          routeLink: '/com-audit',
          icon: BookIcon,
          authorizationFlag: null
        },
        {
          id: 'mbas-data-audit',
          name: t('DATA_AUDIT'),
          routeLink: '/data-audit',
          icon: AssignmentLateIcon,
          authorizationFlag: null
        },
      ]
    },

  ],
  fourthSection: [{
    id: 'mba-Reports',
    name: t('REPORTS'),
    icon: AssessmentIcon,
    items: [
      {
        id: 'mba-region-purchases',
        name: t('REGION_PURCHASES'),
        routeLink: '/region-purchases',
        icon: ListIcon,
        authorizationFlag: 'region_purchase'
      },
      {
        id: 'mba-vessel-purchases',
        name: t('VESSEL_PURCHASES'),
        routeLink: '/vessel-purchases',
        icon: ListIcon,
        authorizationFlag: 'vessel_purchase'
      },
    ]
  }],
  accountSettings: [
    {
      id: 'mbas-account-settings',
      name: t('ACCOUNT_SETTINGS'),
      routeLink: '/account-settings',
      icon: SettingsIcon,
      authorizationFlag: null
    },
  ],
};

const adminSidenav = {
  mainSection: [
    {
      id: 'admin-users',
      name: t('USERS'),
      routeLink: '/',
      icon: GroupIcon,
      authorizationFlag: null
    },
    {
      id: 'admin-roles',
      name: 'Roles',
      routeLink: '/roles',
      icon: SupervisorAccountIcon,
      authorizationFlag: null
    },
    {
      id: 'admin-system',
      name: 'System',
      routeLink: '/system',
      icon: SettingsSystemDaydreamIcon,
      authorizationFlag: null
    },
  ],
  secondarySection: [],
  thirdSection: [],
  // secondarySection: [
  //   {
  //     id: 'admin-usage',
  //     name: 'Usage',
  //     routeLink: '/usage',
  //     icon: DataUsageIcon,
  //     authorizationFlag: null
  //   },
  //   {
  //     id: 'admin-exceptions',
  //     name: 'Exceptions',
  //     routeLink: '/exceptions',
  //     icon: ReportProblemIcon,
  //     authorizationFlag: null
  //   },
  // ],
  accountSettings: [
    {
      id: 'admin-account-settings',
      name: 'Account Settings',
      routeLink: '/account-settings',
      icon: SettingsIcon,
      authorizationFlag: null
    },
  ],
};

const navigator = {
  SUPPLIER: suppliersSidenav,
  BUYER: buyersSidenav,
  MBA: mbasSidenav,
  ADMIN: adminSidenav,
};

export { navigator };
