import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


const ClearCacheButton = ({buttonName,buttonColor, handleClearCache}) => {
    return <>
            <Grid item xs={3}>
                <Button
                    variant="contained"
                    onClick={handleClearCache}
                    color={buttonColor}
                    >
                    {buttonName}
                </Button>
            </Grid>
            </>
}

export default ClearCacheButton