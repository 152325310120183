import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getVesselStoreTypes } from '../../services/vesselsService';

export default function FilterVesselStoreTypes({ vesseStoreType: initialvesseStoreType, getSelectedVesseStoreType }) {

  const [vesseStoreType, setvesseStoreType] = React.useState(0);
  const [vesseStoreTypes, setVesseStoreTypes] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setvesseStoreType(initialvesseStoreType && initialvesseStoreType.id !== null ? initialvesseStoreType : 0);
    setVesseStoreTypes(initialvesseStoreType && initialvesseStoreType.id !== null ? [initialvesseStoreType] : []);
    getVesselStoreTypes().then((res) => {
      setVesseStoreTypes(res.data);
    }).catch((error) => {
    })
  }, [initialvesseStoreType]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    setSearch(value);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onvesseStoreTypesSelectionChange = (event, values) => {
    setvesseStoreType(values ? values : '');
    const selectedVesseStoreType = {
      vesseStoreType: values ? values :  null, // return null when the value is empty string
    };
    getSelectedVesseStoreType(selectedVesseStoreType);
  };


  return (
    <div>
      <Autocomplete
        id={"find-vesseStoreTypes" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'vesseStoreTypes')}
        onChange={onvesseStoreTypesSelectionChange}
        value={vesseStoreTypes.find(v => v.id === vesseStoreType.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={vesseStoreTypes !== null ? vesseStoreTypes : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('WAREHOUSE')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}