import httpService from './httpService';

const getAllVessels = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchVesselList(criteria);
};

const getAllVesselsInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.FetchVesselInfoList(criteria);
};

const getAllVesselSizes = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.FetchVesselSizes(criteria);
};


const getVesselById = (criteria = {}) => {
  return httpService.FetchVessel(criteria);
};

const deleteVessel = (id) => {
  return httpService.DeleteVessel(id);
};

const editVessel = (id, data) => {
   return httpService.UpdateVessel({}, data);
};

const addVessel = (data) => {
  return httpService.CreateVessel({},data);
};

const getAllVesselDwts = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchVesselDwts(criteria);
  
};

const getAllVesselTypes = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchVesselTypes(criteria);
  
};

const getAllVesselAges = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchVesselAges(criteria);
  
};

const clearVesselCache = (criteria = {}) => {
  return httpService.ClearVesselCache({},criteria);
};

const getAllVesselItems = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchVesselItems(criteria);
};


const getVesselStoreTypes = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.GetVesselStoreTypes(criteria);
};

const getVesselStoreStatuses = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.GetVesselStoreStatuses(criteria);
};

const editVesselStoreItem = (id, data) => {
  return httpService.UpdateVesselStoreItem({id}, data);
};

export {
  getAllVessels,
  getAllVesselsInfo,
  getAllVesselDwts,
  getAllVesselTypes,
  getAllVesselAges,
  deleteVessel,
  addVessel,
  editVessel,
  getVesselById,
  getAllVesselSizes,
  clearVesselCache,
  getAllVesselItems,
  getVesselStoreTypes,
  getVesselStoreStatuses,
  editVesselStoreItem
};
