import React, { useEffect, useState } from 'react';
import { getTransactions, deleteTransaction } from '../../services/transactionsService';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import t from '../../helpers/languages';
import { Link as RouterLink } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getSupplyStatus } from '../../services/supplyStatusService';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { TableFilter } from '../../components/tableFilter';
import TableHeader from '../../components/tableHeader';
import { tableHeaders, tableHeadersAdmin } from '../../helpers/transactionsConfig';
import TableActions from '../../components/TableActions/TableActions';
import TransactionRowStatus from '../../components/transactionRowStatus';
import { StatusIndicator } from "../../common/StatusIndicator/StatusIndicator";
import OnlineAssistant from '../../components/onlineAssistant';
import MiniDashboard from '../miniDashboard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const Trasaction = (props) => {

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //   };
  // });
  const filters = localStoreGetObj('transactionFilter');

  const [transactionsState, setTransactionsState] = useState({
    transactions: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {
    },
    isLoading: true,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const [transactionStatusState, setTransactionStatusState] = useState({
    transactionStatuses: []
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10);
  !criteria.page && (criteria.page = 1);
  urlParams.setUrlParams(criteria);


  let filtersData = {};

  filtersData = {
    ...transactionsState.filters,
    port: filters && filters.port && filters.port.id,
    vessel: filters && filters.vessel && filters.vessel.id,
    category: filters && filters.category && filters.category.id,
    search: filters && filters.search,
    date: filters && filters.from,
    status: filters && filters.status,
    per_page: criteria.per_page,
    page: criteria.page
  };

  useEffect(() => {

    const statusName = { type: 'transaction' };
    getSupplyStatus(statusName).then(res => {
      setTransactionStatusState({
        ...transactionStatusState.transactionStatuses,
        transactionStatuses: res.data,
      });
    }).catch((error) => {

    }).finally(() => {

    });

    setTransactionsState({
      ...transactionsState,
      isLoading: true
    });

    getTransactions(filtersData).then(res => {
      const { results } = res.data;
      setTransactionsState({
        ...transactionsState,
        transactions: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setTransactionsState({
        ...transactionsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();

    const isAsc = property === transactionsState.page.order_by && transactionsState.page.order_type === 'desc' ? 'asc' : 'desc';

    setTransactionsState({
      ...transactionsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;
    filtersData.page = transactionsState.page.page;
    filtersData.per_page = transactionsState.page.per_page;

    getTransactions(filtersData).then(res => {
      const { results } = res.data;
      setTransactionsState({
        ...transactionsState,
        transactions: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {

      setTransactionsState({
        ...transactionsState,
        isLoading: false,
      });
    })

  };


  const handleChangePage = (event, newPage) => {
    setTransactionsState({
      ...transactionsState,
      isLoading: true
    });

    const newCriteria = {
      ...transactionsState.filters,
      per_page: transactionsState.page.per_page,
      page: newPage + 1
    };
    getTransactions(newCriteria)
      .then(res => {
        setTransactionsState({
          ...transactionsState,
          isLoading: false,
          transactions: res.data.results,
          page: res.data.page,
          filters: res.data.filters
        });
        urlParams.setUrlParams(res.data.page);
      }).catch((error) => {
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setTransactionsState({
      ...transactionsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...transactionsState.filters,
      page: 1,
      per_page: perPage,
    };

    getTransactions(newCriteria).then(res => {
      setTransactionsState({
        ...transactionsState,
        isLoading: false,
        transactions: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {

      setTransactionsState({
        ...transactionsState,
        isLoading: false,
      });
    });
  };


  const filterData = (data) => {
    setTransactionsState({
      ...transactionsState,
      isLoading: true
    });

    filtersData = {
      ...transactionsState.filters,
      port: data && data.port && data.port.id,
      vessel: data && data.vessel && data.vessel.id,
      category: data && data.category && data.category.id,
      from_date: data && data.from,
      to_date: data && data.to,
      status: data && data.status,
      search: data && data.search,
      per_page: criteria.per_page,
      page: 1
    };

    getTransactions(filtersData).then(res => {
      setTransactionsState({
        ...transactionsState,
        isLoading: false,
        transactions: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('transactionFilter', data);
    }).catch((error) => {

      setTransactionsState({
        ...transactionsState,
        isLoading: false,
      });
    });
  };

  const handleDeleteTransaction = (event, index) => {

    const transactionForDeletion = transactionsState.transactions[index];
    setTransactionsState({
      ...transactionsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Transaction',
        onCancel: () => {
          setTransactionsState({
            ...transactionsState,
            confirmDialog: {
              ...transactionsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteTransaction(transactionForDeletion.id).then(() => {
            const newTransactions = [...transactionsState.transactions];
            newTransactions.splice(index, 1);
            setTransactionsState({
              ...transactionsState,
              transactions: newTransactions,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const tableFilterComponent = () => {
    return <TableFilter
      companyField={false}
      searchByText={true}
      referenceNumber={(filters && filters.search) ? filters.search : transactionsState.filters.search}
      company={(filters && filters.company) ? filters.company : transactionsState.filters.company}
      vessel={(filters && filters.vessel) ? filters.vessel : transactionsState.filters.vessel}
      productCategory={(filters && filters.category) ? filters.category : transactionsState.filters.category}
      port={(filters && filters.port) ? filters.port : transactionsState.filters.port}
      from={(filters && filters.from) ? filters.from : transactionsState.filters.from_date}
      to={(filters && filters.to) ? filters.to : transactionsState.filters.to_date}
      status={(filters && filters.status) ? filters.status : transactionsState.filters.status}
      statuses={transactionStatusState.transactionStatuses}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={props.userState.user.userRole !== 'MBA'?tableHeaders:tableHeadersAdmin}
      onSelectAllClick={() => { }}
      order={transactionsState.page.order_type}
      orderBy={transactionsState.page.order_by}
      rowCount={transactionsState.page.length} />;
  };

  const dashboard = () => {
    return <MiniDashboard />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {transactionsState.transactions && transactionsState.transactions.map((transaction, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>
                <IconButton
                  className='table-more-info'
                  aria-label="expand row"
                  style={{padding:'0px'}}
                  component={RouterLink}
                  to={{ pathname: `/transactions/${transaction.id}` }}
                >
                  <ManageSearchIcon></ManageSearchIcon>
                </IconButton>
              </TableCell>
              <TableCell>
                {transaction.date && transaction.date.slice(0, 10)}
              </TableCell>
              <TableCell>{transaction.vessel && transaction.vessel.name}</TableCell>
              <TableCell>
                {transaction.port && transaction.port.name}
              </TableCell>
              <TableCell>{transaction.category && transaction.category.name}</TableCell>
              <TableCell>
                <StatusIndicator status={transaction.status}>
                  <TransactionRowStatus status={transaction.status} />
                </StatusIndicator>
              </TableCell>
              {props.userState.user.userRole === 'MBA' && <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: false,
                    callback: (e) => null
                  },
                  delete: {
                    enabled: true,
                    callback: (e) => handleDeleteTransaction(e, index)
                  },
                  close: {
                    enabled: false,
                    callback: (e) => null
                  },
                  save: {
                    enabled: false,
                    callback: (e) => null
                  }
                }} />
              </TableCell>}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  return (
    <>
      <OnlineAssistant
        user={props.userState.user.userRole}
      />
      <ProcureDataTable
        toolbarTitle={t('TRANSACTIONS')}
        pagination={{
          total: transactionsState.page.total || 0,
          page: transactionsState.page.page - 1 || 0,
          perPage: transactionsState.page.per_page || 10,
          count: transactionsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={() => { }}
        confirmDialog={transactionsState.confirmDialog}
        dashboard={dashboard}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        isLoading={transactionsState.isLoading}
      />
    </>
  );
};

export default Trasaction;
