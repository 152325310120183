import React from 'react';
import Grid from '@mui/material/Grid';
import t from '../../helpers/languages';
import InlineEditAutocompleteCurrencies from '../../components/inlineEditAutocompleteCurrencies';
import InlineEditNotes from '../../components/inlineEditNotes';
import InlineEditDiscount from '../../components/inlineEditDiscount';
import OutlineDisplay from '../../components/outlineDisplay';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const QuoteInfo = (props) => {

    const selectedCurrency = (data) => {
        props.getSelectedCurrency(data);
    };

    const quoteNotes = (data) => {
        props.getNotes(data);
    };

    const quoteExtraCostNotes = (data) => {
        props.getExtraCostNotes(data);
    };

    const quoteDeliveryNotes = (data) => {
        props.getDeliveryNotes(data);
    };

    const quoteDiscount = (data) => {
        props.getDiscount(data);
    };

    const quoteReferenceNumber = (data) => {
        props.getReferenceNumber(data);
    };

    return (
        <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-end"
            direction="row"
            spacing={3}>
            {props.alert && <Grid item xs={12}>
                <Alert severity="error">
                    <Typography variant="subtitle1" gutterBottom>
                        {props.alert}
                    </Typography>
                </Alert>
            </Grid>}
            <Grid item xs={3}>
                <OutlineDisplay label={t('VESSEL')} value={props.vessel} />
            </Grid>
            <Grid item xs={3}>
                <OutlineDisplay label={t('PORT')} value={props.port} />
            </Grid>
            <Grid item xs={2}>
                {props.edit ? <InlineEditNotes notes={props.referenceNumber} saved={props.savedReferenceNumber} label={t('REFERENCE_NUMBER')} error={props.error}
                    getNotes={quoteReferenceNumber} req={true} /> : <OutlineDisplay label={t('REFERENCE_NUMBER')} value={props.referenceNumber} />}
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('SUBMITTED')} value={props.submitted} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('DUE')} value={props.periodDue} />
            </Grid>
            <Grid item xs={6}>
                {props.userRole === "BUYER" ?
                    <OutlineDisplay label={t('SUPPLIER')} value={props.supplier} /> :
                    <OutlineDisplay label={t('BUYER')} value={props.buyer} />
                }
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('CATALOGUE')} value={props.catalogue} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('STATUS')} value={props.status} />
            </Grid>
            <Grid item xs={2}>
                {!props.check && props.edit ? <InlineEditAutocompleteCurrencies currency={props.currency} saved={props.savedCurrency} error={props.error}
                    getSelectedCurrency={selectedCurrency} req={true} /> : <OutlineDisplay label={t('CURRENCY')} value={props.currency.name} />}</Grid>
            <Grid item xs={9}>
                {props.edit ?
                    <InlineEditNotes notes={props.notes} saved={props.savedNotes} error={props.error}
                        getNotes={quoteNotes} /> : <OutlineDisplay label={t('NOTES')} value={props.notes} />}
            </Grid>
            <Grid item xs={1}>
                {props.edit ?
                    <InlineEditDiscount value={props.discount} saved={props.savedDiscount} error={props.error} label={t('DISCOUNT')}
                        getValue={quoteDiscount} /> : <OutlineDisplay label={t('DISCOUNT')} value={props.discount ? (props.discount).toString() + '%' : '0%'} />}
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('TOTAL_COST')} value={props.amount} />
            </Grid>
            <Grid item xs={10}>
                {props.edit ?
                    <InlineEditNotes notes={props.deliveryNotes} saved={props.savedDeliveryNotes} label={t('DELIVERY_METHOD')} error={props.error}
                        getNotes={quoteDeliveryNotes} /> : <OutlineDisplay label={t('DELIVERY_METHOD')} value={props.deliveryNotes} />}
            </Grid>
            <Grid item xs={2}>
                {props.edit ?
                    <InlineEditNotes notes={props.extraCostNotes} saved={props.savedExtraCostNotes} label={t('EXTRA_CHARGES')} error={props.error}
                        getNotes={quoteExtraCostNotes} /> : <OutlineDisplay label={t('EXTRA_CHARGES')} value={props.extraCostNotes} />}
            </Grid>
        </Grid>
    )
}


export default QuoteInfo;