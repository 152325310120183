
const tableHeaders = [
  { id: 'view', disablePadding: false, },
  { id: 'date', disablePadding: false, label: 'Date'},
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'port', disablePadding: false, label: 'Port' },
  { id: 'category', disablePadding: false, label: 'Catalogue'},
  { id: 'status', disablePadding: false, label: 'Status', align:'center' },
];

const tableHeadersAdmin = [
  { id: 'view', disablePadding: false, },
  { id: 'date', disablePadding: false, label: 'Date'},
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'port', disablePadding: false, label: 'Port' },
  { id: 'category', disablePadding: false, label: 'Catalogue'},
  { id: 'status', disablePadding: false, label: 'Status', align:'center' },
  { id: 'row-actions', disablePadding: false, },
];

export { tableHeaders, tableHeadersAdmin };
