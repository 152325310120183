import React from 'react';
import useStyles from './styles';
import { clearContractsCache } from '../../services/contractsService';
import { clearVesselCache } from '../../services/vesselsService';
import { clearUnitCache } from '../../services/unitsService';
import { clearCountryCache } from '../../services/countriesService';
import { clearCompanyCache } from '../../services/companiesService';
import { refreshMaterializedViews } from '../../services/reportsService';
import userService from '../../services/userService';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import ClearCacheButton from '../../components/clearCache';
import './_clearCacheRefreshViews.scss';
import { Divider } from '@mui/material';


const ClearCacheRefreshViews = (props) => {

  const classes = useStyles();

  const handleClearContractsCache = (event, newPage) => {

    clearContractsCache().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  const handleClearVesselCache = (event, newPage) => {

    clearVesselCache().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  const handleClearUnitCache = (event, newPage) => {

    clearUnitCache().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  const handleClearCountryCache = (event, newPage) => {

    clearCountryCache().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  const handleClearCompanyCache = (event, newPage) => {

    clearCompanyCache().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  const handleClearUserCache = (event, newPage) => {

    userService.clearUserCache().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  const handleRefreshMaterializedViews = (event, newPage) => {

    refreshMaterializedViews().then(res => {

    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        elevation={4}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          spacing={3}>

          <Grid item xs={12}>
            <div className={classes.clearTitle}>
              <Typography variant="h5" gutterBottom>
                Clear Cache / Refresh views
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
              spacing={3}>
              <ClearCacheButton
                buttonName={'Clear Contracts Cache'}
                buttonColor={'primary'}
                handleClearCache={handleClearContractsCache} />
              <ClearCacheButton
                buttonName={'Clear Vessels Cache'}
                buttonColor={'primary'}
                handleClearCache={handleClearVesselCache} />
              <ClearCacheButton
                buttonName={'Clear Units Cache'}
                buttonColor={'primary'}
                handleClearCache={handleClearUnitCache} />
              <ClearCacheButton
                buttonName={'Clear Countries Cache'}
                buttonColor={'primary'}
                handleClearCache={handleClearCountryCache} />
              <ClearCacheButton
                buttonName={'Clear Companies Cache'}
                buttonColor={'primary'}
                handleClearCache={handleClearCompanyCache} />
              <ClearCacheButton
                buttonName={'Clear Users Cache'}
                buttonColor={'primary'}
                handleClearCache={handleClearUserCache} />
            </Grid>
            <Divider sx={{  m: 2.5 }} flexItem />
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                direction="row"
                spacing={3}>
                <ClearCacheButton
                  buttonName={'Refresh Views'}
                  buttonColor={'secondary'}
                  handleClearCache={handleRefreshMaterializedViews} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};


export default ClearCacheRefreshViews;
