import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeaderInfo from '../../components/tableHeaderInfo';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { tableHeadersPortsStats } from '../../helpers/portsStatsConfig';
import './style.scss';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    },
    blueHover: {
        "&:hover": {
            color: '#1565c0'
        },
    }
});

const PortStatsTable = (props) => {

    const classes = useStyles();

    return (
        <section
            className='port_stats_table'
        >
            <Typography variant="h5" gutterBottom>
                {props.toolbarTitle}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {props.subtitleToolbarTitle}
            </Typography>
            <TableContainer>
                <Table className={classes.table} size="small" aria-label="simple-table">
                    <TableHeaderInfo headCells={tableHeadersPortsStats} />
                    <TableBody>
                        {props.stats.map((stat, index) => (
                            <TableRow key={index}>
                                <TableCell>{stat.port !== undefined && stat.port.name}</TableCell>
                                <TableCell>{stat.totalAmount.toFixed(2)}</TableCell>
                                <TableCell>{stat.percent.toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </section >
    );
}

export default PortStatsTable;