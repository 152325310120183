import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllUnits, getUnitPackage } from '../../services/unitsService';
import InputAdornment from '@mui/material/InputAdornment';
export default function FilterUnits({ 
  unit: initialUnit,
  port:initialPort=null, 
  supplier:initialSupplier=null, 
  product: initialProduct=null, 
  inputAdornment=null,
  onFocusa=null,
  inputRefa=null,
  searchforpack=null,
  getSelectedUnitPack,
  getSelectedUnit }) {

  const [unit, setUnit] = React.useState(0);
  const [units, setUnits] = React.useState([]);
  const [openUnit, setOpenUnit] = React.useState(false);
  const [search, setSearch] = React.useState('');
  
  useEffect(() => {
    setSearch('');
    setUnit(initialUnit && initialUnit.id !== null ? initialUnit : 0);
    setUnits(initialUnit && initialUnit.id !== null ? [initialUnit] : []);
  }, [initialUnit]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "unit" && value && value.length >= 2 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    unitSearch({
      search: val,
      port: initialPort,
      supplier: initialSupplier,
      product: initialProduct,
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), [initialProduct]);

  const onUnitSelectionChange = (event, values) => {
    setUnit(values ? values : '');
    const selectedUnit = {
      unit: values ? values : null, // send null in case the user deletes the data.
    };
    getSelectedUnit(selectedUnit);

    searchforpack&&getUnitPack(selectedUnit);
  };

  const getUnitPack = (criteria) => {
    if (criteria.unit?.id) {
      getUnitPackage(criteria.unit.id).then((res) => {
        const selectedPack = {
          pack: res.data,
        };
        getSelectedUnitPack(selectedPack);
      }).catch((error) => {
      })
    } else {
      const selectedPack = {
        pack: {id:null},
      };
      getSelectedUnitPack(selectedPack);
    }


  }

  const unitSearch = (criteria) => {

    return getAllUnits(criteria).then((res) => {
      setUnits(res.data);
    }).catch((error) => {
    })
  }

  return (
    <div>
      <Autocomplete
        id="find-unit"
        fullWidth
        open={openUnit}
        onOpen={() => {
          setOpenUnit(true);
        }}
        onClose={() => {
          setOpenUnit(false);
        }}
        onFocus={onFocusa}
        
        onInputChange={e => handleInputChange(e, 'unit')}
        onChange={onUnitSelectionChange}
        value={units.find(v => v.id === unit.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={units !== null ? units : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('UNIT')}
            variant="outlined"
            inputRef={inputRefa}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                inputAdornment&&<InputAdornment position="start">
                 {inputAdornment + ' /'}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}